<template>
  <div>
    <hero-bar :has-right-visible="false">
      {{ $getTranslation('general.views.logActivities') }}
    </hero-bar>
    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced" icon="account-multiple">
          <div v-if="!loading">

            <app-table
              :columns="columns"
              :page="page"
              :total="total"
              :editRoute="null"
              @onPageChange="onPageChange"
              :pagination-top="true"
              :pagination-bottom="true"
            />

          </div>
          <app-section-empty v-else :is-loading="loading"></app-section-empty>
      </card-component>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { ApiLogActivities } from "@/api-routes.js"
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
import Table from '@/components/elements/table/Table'

export default {
  name: 'activities',
  mixins: [ListTemplateMixin],
  components: {
    'app-table': Table
  },
  data () {
    return {
      endpoint: ApiLogActivities,
      // Pagination
      total: 0,
      page: 1,
      perPage: 15,
      // Table
      columns: {'name':'form', 'description':'form', 'causer_type': 'form', 'properties':'form', 'created':'general'}
    }
  },

  created () {
    this.fetchPaginatedItems ()
    // console.log('LogActivities created')
  }
}
</script>
